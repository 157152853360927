import { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";

import ArticleDataService from "../services/article.service";
import IArticleData from '../types/article.type';
import IArticleListData from "../types/article-list.type";
import LoadingSpinner from './loading.component';
import ArticleActions from "./article-actions.component";
import TagList from "./tag-list.component";
import IListFiltersData from "../types/list-filters.type";
import { Button, Form, InputGroup } from "react-bootstrap";

type Props = {
  filters?: IListFiltersData
} & RouteComponentProps;

type State = {
  articles: IArticleListData
  currentIndex: number
  loading: boolean
  showEmptyListInfo: boolean
  searchQuery: string
  isShowingSearchResults: boolean
};

const MAX_EXERPT_LENGTH = 150;

class ArticleList extends Component<Props, State> {
  private emptyArticleListState: IArticleListData;

  constructor(props: Props) {
    super(props);
    this.retrieveArticles = this.retrieveArticles.bind(this);
    this.highlightItem = this.highlightItem.bind(this);
    this.openArticle = this.openArticle.bind(this);
    this.reloadPage = this.reloadPage.bind(this);
    this.reloadSingleArticleData = this.reloadSingleArticleData.bind(this);

    this.emptyArticleListState = {
      page: -1,
      pages: 0,
      count: 0,
      size: 10,
      articles: [],
    };

    this.state = {
      articles: this.emptyArticleListState,
      currentIndex: -1,
      loading: false,
      showEmptyListInfo: false,
      searchQuery: '',
      isShowingSearchResults: false
    };
  }

  componentDidMount() {
    this.retrieveArticles(false);
  }

  retrieveArticles(ignoreLoading: boolean) {
    const { searchQuery } = this.state;
    this.setState({ loading: true && !ignoreLoading });
    let { filters } = this.props;

    const fetchArticles = (filters?: IListFiltersData) => {
      ArticleDataService.getAll({ page: this.state.articles.page + 1, size: this.state.articles.size, ...filters })
        .then(response => {
          response.data.articles = this.state.articles.articles.concat(response.data.articles);
          this.setState({
            articles: response.data,
            loading: false
          });

          if (response.data.articles.length === 0) {
            this.setState({ showEmptyListInfo: true });
          }
          console.log(response.data);
        })
        .catch(err => {
          if (err.response?.data?.error?.extra?.name === 'missing_user') {
            this.props.history.push('/');
          } else {
            this.setState({ loading: false });
          }
        });
    }

    if (searchQuery !== '') {
      filters = {
        ...filters,
        keywords: searchQuery
      };
      this.setState(
        {
          articles: this.emptyArticleListState,
          isShowingSearchResults: true
        },
        () => fetchArticles(filters)
      );
    } else {
      fetchArticles(filters)
    }
  }

  reloadPage(pageToReload: number) {
    const { articles, searchQuery } = this.state;
    const currentArticleList = articles.articles
    const itemsPerPage = articles.size;
    const pageFirstIndex = pageToReload * itemsPerPage - itemsPerPage;
    let { filters } = this.props;

    if (searchQuery !== '') {
      filters = {
        ...filters,
        keywords: searchQuery
      };
    }

    ArticleDataService.getAll({ page: pageToReload, size: this.state.articles.size, ...filters })
      .then(response => {
        let refreshedPage = response.data.articles;
        currentArticleList.splice(pageFirstIndex, itemsPerPage, ...refreshedPage);
        let uniqueArticles = currentArticleList.filter((v, i, a) => a.findIndex(tag => tag.id === v.id) === i);

        this.setState({
          articles: {
            ...articles,
            articles: uniqueArticles
          }
        });

        if (uniqueArticles.length === 0) {
          this.setState({ showEmptyListInfo: true });
        }
        console.log(response.data);
      })
      .catch(err => {
        console.log(err);
      }).finally(() => {
        this.setState({ loading: false });
      });
  }

  reloadSingleArticleData(updatedArticleData: IArticleData) {
    const { articles } = this.state;
    let indexOfItem = articles.articles.findIndex(article => article.id === updatedArticleData.id);

    articles.articles[indexOfItem] = { ...articles.articles[indexOfItem], ...updatedArticleData };

    this.setState({ articles });
  }

  highlightItem(index: number) {
    this.setState({ currentIndex: index });
  }

  openArticle(articleId: number, isReadable: boolean, originalUrl: string) {
    if (isReadable) {
      this.props.history.push(`/articles/${articleId}`);
    } else {
      window.open(originalUrl, "_blank");
    }
  }

  render() {
    const { articles, currentIndex, loading, showEmptyListInfo, searchQuery, isShowingSearchResults } = this.state;

    return (
      <>
        {showEmptyListInfo ? (
          <div className="d-flex justify-content-sm-center flex-column w-75 mx-auto">
            <h5>looks like you have no links saved or visible in this section.</h5>
            <h5>
              install our web extension (<a href="https://addons.mozilla.org/firefox/addon/pouch-web-extension/" target="_blank" rel="noreferrer">for firefox</a> and chrome coming soon) or click "add" up there, to save a link to an article in your pouch.
            </h5>
          </div>
        ) : (
          <div>
            <Form.Group >
              <InputGroup className="search-input">
                <Form.Control
                  type="text"
                  placeholder="search for articles"
                  aria-describedby="inputGroupAppend"
                  onChange={e => this.setState({ searchQuery: e.target.value })}
                  value={searchQuery}
                  required
                />

                {isShowingSearchResults ? (
                  <InputGroup.Append id="inputGroupAppend" className="ml-1 mr-1">
                    <Button variant="outline-danger" type="submit" onClick={() => this.setState({isShowingSearchResults: false, searchQuery: '', articles: this.emptyArticleListState}, () => this.retrieveArticles(false))}>
                      clear
                    </Button>
                  </InputGroup.Append>
                ) : (<></>)}

                <InputGroup.Append id="inputGroupAppend" >
                  <Button variant="secondary" onClick={() => this.retrieveArticles(false)} type="submit" >
                    search
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </Form.Group>

            {loading ? (
              <LoadingSpinner />
            ) : (
              <div>
                <ul className="article-list list-group">
                  <InfiniteScroll
                    dataLength={this.state.articles.articles.length}
                    next={() => this.retrieveArticles(true)}
                    hasMore={this.state.articles.page !== this.state.articles.pages && this.state.articles.pages > 0}
                    loader={<LoadingSpinner />}
                  >
                    {articles && articles.articles.map((article: IArticleData, index: number) => (
                      <li
                        className={`article-list list-group-item ${index === currentIndex ? "active" : ""}`}
                        key={index}
                        onMouseEnter={() => this.highlightItem(index)}
                        onMouseLeave={() => this.highlightItem(-1)}
                      >
                        <h4
                          className="article-item"
                          onClick={() => this.openArticle(article.id, article.isReadable, article.originalUrl)}
                        >
                          <strong>{article.articleContent?.title}</strong>
                        </h4>
                        <div>{article.articleContent.excerpt?.length > MAX_EXERPT_LENGTH ? `${article.articleContent?.excerpt.slice(0, MAX_EXERPT_LENGTH)} [...]` : article.articleContent.excerpt}</div>
                        <div className="article-metadata">
                          <TagList tags={article.tags} />
                          <ArticleActions article={article} onArticleDelete={this.reloadPage} onArticleEdit={this.reloadSingleArticleData} articleList={articles} />
                        </div>
                      </li>
                    ))}
                  </InfiniteScroll>
                </ul>
              </div>
            )}
          </div>
        )}
      </>
    );
  }
}

export default withRouter(ArticleList);